import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-itdemo',
  templateUrl: './itdemo.component.html',
  styleUrls: ['./itdemo.component.css']
})
export class ItdemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
