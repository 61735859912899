import { Component, HostListener } from '@angular/core';
import { AngularFireDatabase,AngularFireObject } from '@angular/fire/database';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { interval } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

declare function bmPlayer(p:string,pt:string, params:any): any;

@Component({
  selector: 'akuh-app',
  templateUrl: './akuh.component.html',
  styleUrls: ['./akuh.component.css']
})

export class AkuhComponent {
  pageID:string|null=null;
  akuCampus:  AngularFireObject<any>;
  akuStatus:  AngularFireObject<any>;
  isLive = 'none';
  mainStream:string="Thankyou"; // Home Counter Test Global Live Thankyou
  mainStreamTest:string="Thankyou"; // Home Counter Global Test Live Thankyou
  mainPage:string="Thankyou"; // Home Counter Test Global Live Thankyou
  streamLink:string[]=[];
  akuPlayer:number=0;
  akuCounter="0";
  akuDate:number = new Date("September 06, 2021 12:30 GMT+0").getTime();
  akuEventD:number=0;
  akuEventH:number=0;
  akuEventM:number=0;
  akuEventS:number=0;
  diff:number=0;
  diffIV = interval(1000);

  akuPlayerContent="<img src='assets/thankyouakuh.jpg' width='100%' height='auto' alt='AKUH Live'>";
  akuCampuses:string[]= [
    'Global',                                 //0
    'SONAM<br>Pakistan',                      //1
    'Medical College<br>Pakistan',            //2
    'IED, Pakistan<br>ISMC, United Kingdom',  //3    
    'SONAM, Uganda<br>IED, Uganda',           //4
    'SONAM<br>Kenya',                         //5
    'SONAM<br>Tanzania',                      //6  
    'Medical College, Kenya<br>IED, Kenya',   //7
    'Medical College, Tanzania<br>IED Tanzania',//8
    'GSMC<br>Kenya'                           //9
  ];

  akuCampusesTxt:string[]= [
    this.akuCampuses[0].replace('<br>',' and '),
    this.akuCampuses[1].replace('<br>',' and '),
    this.akuCampuses[2].replace('<br>',' and '),
    this.akuCampuses[3].replace('<br>',' and '),
    this.akuCampuses[4].replace('<br>',' and '),
    this.akuCampuses[5].replace('<br>',' and '),
    this.akuCampuses[6].replace('<br>',' and '),
    this.akuCampuses[7].replace('<br>',' and '),
    this.akuCampuses[8].replace('<br>',' and '),
    this.akuCampuses[9].replace('<br>',' and ')
  ];

  akuStream:string[]= [
    'https://ismaili.global.ssl.fastly.net/livehttporigin/ngrp:pzp_all/playlist.m3u8',        //0 Global Stream 
    'https://iistream.global.ssl.fastly.net/origin/ngrp:pakistansonam_all/playlist.m3u8', //2 PK: Campus 1
    'https://iistream.global.ssl.fastly.net/origin/ngrp:pakistanmc_all/playlist.m3u8',    //1 PK: Campus 1
    'https://iistream.global.ssl.fastly.net/origin/ngrp:pakistanied_all/playlist.m3u8',   //3 PK: Campus 3
    'https://iistream.global.ssl.fastly.net/origin/ngrp:uganda_all/playlist.m3u8',        //9 UG: Campus 1
    'https://iistream.global.ssl.fastly.net/origin/ngrp:kenyasonam_all/playlist.m3u8',    //5 KE: Campus 1
    'https://iistream.global.ssl.fastly.net/origin/ngrp:tanzaniasonam_all/playlist.m3u8', //8 TZ: Campus 1
    'https://iistream.global.ssl.fastly.net/origin/ngrp:kenyamc_all/playlist.m3u8',       //4 KE: Campus 2
    'https://iistream.global.ssl.fastly.net/origin/ngrp:tanzaniaied_all/playlist.m3u8',   //7 TZ: Campus 2
    'https://iistream.global.ssl.fastly.net/origin/ngrp:kenyagsmc_all/playlist.m3u8',     //6 KE: Campus 3  
    'https://ismaili.global.ssl.fastly.net/livehttporigin/ngrp:pzptest_all/playlist.m3u8'  //Test Stream
  ];


  streamStatus:string[]= [
    '0',  //Global
    '0',  //1
    '0',  //2
    '0',  //3
    '0',  //4
    '0',  //5
    '0',  //6
    '0',  //7
    '0',  //8
    '0'   //9
  ];

  mainWidth="100%";
  counterSize="10vw";

  @HostListener('window:resize', ['$event'])
  onResize() { this.mainResize(); }

  mainResize(){
    this.mainWidth=((window.innerHeight-100)*1.75)+"px";
    // if (window.innerWidth > ((window.innerHeight-100)*1.75) ) {
    //   this.counterSize=(((window.innerHeight-100)*1.75)/12)+"px";  
    // } else {
    //   this.counterSize=((window.innerWidth)/12)+"px";  
    // }   
  }

  constructor (private akuDB: AngularFireDatabase, private akuGA: AngularFireAnalytics, private route: ActivatedRoute) {
    
    this.pageID = this.route.snapshot.paramMap.get('id');

    this.mainResize();

    if (this.pageID=="aku-AgG8qCQd00wYYQa6Wgt89s2VKz") {
      this.akuCampus = akuDB.object('AKU/Test');
      this.akuCampus.snapshotChanges().subscribe(action => {
        this.mainStreamTest = action.payload.val().mainStream;
        this.playEvent(this.mainStreamTest,0);   
      });
    } else {
      this.akuCampus = akuDB.object('AKU/Main');
      this.akuCampus.snapshotChanges().subscribe(action => {
        if((window.location.href).includes('convocation')==true) {
          this.mainStream = 'ThankyouPK';
        } else {
          this.mainStream = action.payload.val().mainStream;
        }
        this.isLive = action.payload.val().isLive;
        this.playEvent(this.mainStream,0);   
      });
    }
  
    this.akuStatus = akuDB.object('AKU/streamStatus');
    this.akuStatus.snapshotChanges().subscribe(action => {
      this.streamStatus[0] = String(action.payload.val().C0);
      this.streamStatus[1] = String(action.payload.val().C1);
      this.streamStatus[2] = String(action.payload.val().C2);
      this.streamStatus[3] = String(action.payload.val().C3);
      this.streamStatus[4] = String(action.payload.val().C4);
      this.streamStatus[5] = String(action.payload.val().C5);
      this.streamStatus[6] = String(action.payload.val().C6);
      this.streamStatus[7] = String(action.payload.val().C7);
      this.streamStatus[8] = String(action.payload.val().C8);
      this.streamStatus[9] = String(action.payload.val().C9);
    });
  }

  akuCountDown(){
    this.diffIV.subscribe(n=>{
        this.diff = (this.akuDate - new Date().getTime()); 
        this.akuEventD = Math.floor(this.diff / (1000 * 60 * 60 * 24));
        this.akuEventH = Math.floor((this.diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.akuEventM = Math.floor((this.diff % (1000 * 60 * 60)) / (1000 * 60));
        this.akuEventS = Math.floor((this.diff % (1000 * 60)) / 1000);
      });
  }

  playEvent(page:string, campus:number) { 

    this.mainPage=page;
    let pageTitle=''; 
    if (Number(this.streamStatus[campus]) == 0) {
      this.akuPlayer=campus;
    // if (campus == 0 ) { this.mainStream=page; }
    } 

    if (page == 'Live' ) {
      pageTitle = 'AKU_'+page +' '+this.akuCampusesTxt[campus];
    } else {
      pageTitle = 'AKU_'+page;
    }
    this.akuGA.logEvent(pageTitle);
    
    switch(page) {
      case 'Home':
        this.isLive = 'none';
        this.akuPlayerContent="<video muted autoplay loop width='100%' height='100%' id='akumVideo'><source src='assets/akuh.mp4' type='video/mp4'></video>";
        bmPlayer(page, pageTitle, this.akuStream[campus]);
        break;
      case 'Test':

        this.isLive = 'block';
        this.akuPlayerContent="";
        bmPlayer(page, pageTitle, this.akuStream[10]);
        break;
      case 'Live':
        this.isLive = 'block';
        this.akuPlayerContent="";
        if (Number(this.streamStatus[campus]) == 0) { bmPlayer(page, pageTitle, this.akuStream[campus]); }
        break;   
      case 'Global':
        this.isLive = 'block';
        this.akuPlayerContent="";
        bmPlayer(page, pageTitle, this.akuStream[campus]);
        break;                    
      case 'Counter':
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/counterakuh.jpg' width='100%' height='auto' alt='AKUH Live'>";
        bmPlayer(page, pageTitle, this.akuStream[campus]);
        this.akuCountDown();
        break;              
      case 'Issue':
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/errorakuh.jpg' width='100%' height='auto' alt='AKUH Live'>";
        bmPlayer(page, pageTitle, this.akuStream[campus]);
        break;          
      case 'Thankyou':
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/thankyouakuh.jpg' width='100%' height='auto' alt='AKUH Live'>";
        bmPlayer(page, pageTitle, this.akuStream[campus]);
        break;
      default:
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/thankyouakuh.jpg' width='100%' height='auto' alt='AKUH Live '>";
        bmPlayer(page, pageTitle, this.akuStream[campus]);
        break;           
    }
  }
}