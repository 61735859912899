import { Component, HostListener } from '@angular/core';
import { AngularFireDatabase,AngularFireObject } from '@angular/fire/database';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { interval } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

declare function bmPlayer(p:string,pt:string, params:any): any;
declare function bmStopPlayer(): any;

@Component({
  selector: 'live-app',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})

export class LiveComponent {
  pageID: string|null=null;
  akuObj: AngularFireObject<any>;
  
  safeURL;
  firstLoad=0;
  akuPlayerContent;
  isLive = 'none';
  mainPage:string="AKU"; // Home Counter Test Global Live Thankyou

  mainStream:string="AKU"; // Home Counter Test Global Live Thankyou  Manual
  mainStreamTest:string="AKU"; // Home Counter Global Test Live Thankyou
  streamLinkM3U8:string[]=['', '', '', '', '', '', '', '' ];
  streamLinkYT:string[]=['', '', '', '', '', '', '', '' ];
  streamTitle:string[]=['', '', '', '', '', '', '', '' ];
  streamTitleTxt:string[]=['', '', '', '', '', '', '', '' ];
  streamStatus:string[]=['O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O' ];
  akuPlayer:number=0;
  akuPlayerVideo:string='M';
  
  akuCounter="0";
  akuDate:number = new Date("Feb 17, 2024 11:15 GMT+0").getTime();
  akuEventD:number=0;
  akuEventH:number=0;
  akuEventM:number=0;
  akuEventS:number=0;
  diff:number=0;
  diffIV = interval(1000);
  
  mainWidth="100%";
  counterSize="10vw";
  counterTSize="1.5vw";

  @HostListener('window:resize', ['$event'])
  onResize() { this.mainResize(); }

  mainResize(){
    this.mainWidth=((window.innerHeight-300)*1.75)+"px";
    if (window.innerWidth > ((window.innerHeight-300)*1.75) ) {
      this.counterSize=(((window.innerHeight-300)*1.75)/12)+"px";
      this.counterTSize=(((window.innerHeight-300)*1.75)/55)+"px";
    } else {
      this.counterSize=((window.innerWidth)/12)+"px";  
      this.counterTSize=((window.innerWidth)/55)+"px";
    }   
  }

  constructor (private akuDB: AngularFireDatabase, private akuGA: AngularFireAnalytics, private route: ActivatedRoute, private _sanitizer: DomSanitizer) {
   
    this.pageID = this.route.snapshot.paramMap.get('id');
    this.mainResize();

    this.safeURL=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/OHt4ThRNFys?autoplay=1&modestbranding=1&enablejsapi=1&playsinline=1&wmode=opaque');
    this.akuPlayerContent="<img src='assets/aku.jpg' width='100%' height='auto' alt='AKU Live'>";

  this.playEvent(this.mainStream,0); 
    this.akuObj = akuDB.object('AKU-F2023');
    this.akuObj.snapshotChanges().subscribe(action => {
      if (this.streamTitle[0] != String(action.payload.val().streamTitle.C0)) { this.streamTitle[0] = String(action.payload.val().streamTitle.C0); }
      if (this.streamTitle[1] != String(action.payload.val().streamTitle.C1)) { this.streamTitle[1] = String(action.payload.val().streamTitle.C1); }
      if (this.streamTitle[2] != String(action.payload.val().streamTitle.C2)) { this.streamTitle[2] = String(action.payload.val().streamTitle.C2); }
      if (this.streamTitle[3] != String(action.payload.val().streamTitle.C3)) { this.streamTitle[3] = String(action.payload.val().streamTitle.C3); }
      if (this.streamTitle[4] != String(action.payload.val().streamTitle.C4)) { this.streamTitle[4] = String(action.payload.val().streamTitle.C4); }
      if (this.streamTitle[5] != String(action.payload.val().streamTitle.C5)) { this.streamTitle[5] = String(action.payload.val().streamTitle.C5); }
      if (this.streamTitle[6] != String(action.payload.val().streamTitle.C6)) { this.streamTitle[6] = String(action.payload.val().streamTitle.C6); }
      if (this.streamTitle[7] != String(action.payload.val().streamTitle.T0)) { this.streamTitle[7] = String(action.payload.val().streamTitle.T0); }

      this.streamTitleTxt = [
        this.streamTitle[0].replace('<br>',' and '),
        this.streamTitle[1].replace('<br>',' and '),
        this.streamTitle[2].replace('<br>',' and '),
        this.streamTitle[3].replace('<br>',' and '),
        this.streamTitle[4].replace('<br>',' and '),
        this.streamTitle[5].replace('<br>',' and '),
        this.streamTitle[6].replace('<br>',' and '),
        this.streamTitle[7].replace('<br>',' and '),
      ];

      if (this.streamStatus[0] != String(action.payload.val().streamStatus.C0)) { this.streamStatus[0] = String(action.payload.val().streamStatus.C0); }
      if (this.streamStatus[1] != String(action.payload.val().streamStatus.C1)) { this.streamStatus[1] = String(action.payload.val().streamStatus.C1); }
      if (this.streamStatus[2] != String(action.payload.val().streamStatus.C2)) { this.streamStatus[2] = String(action.payload.val().streamStatus.C2); }
      if (this.streamStatus[3] != String(action.payload.val().streamStatus.C3)) { this.streamStatus[3] = String(action.payload.val().streamStatus.C3); }
      if (this.streamStatus[4] != String(action.payload.val().streamStatus.C4)) { this.streamStatus[4] = String(action.payload.val().streamStatus.C4); }
      if (this.streamStatus[5] != String(action.payload.val().streamStatus.C5)) { this.streamStatus[5] = String(action.payload.val().streamStatus.C5); }
      if (this.streamStatus[6] != String(action.payload.val().streamStatus.C6)) { this.streamStatus[6] = String(action.payload.val().streamStatus.C6); }
      if (this.streamStatus[7] != String(action.payload.val().streamStatus.T0)) { this.streamStatus[7] = String(action.payload.val().streamStatus.T0); }

      //M3U* }
      if (this.streamLinkM3U8[0] != String(action.payload.val().streamLink.C0M)) { this.streamLinkM3U8[0] = String(action.payload.val().streamLink.C0M); }
      if (this.streamLinkM3U8[1] != String(action.payload.val().streamLink.C1M)) { this.streamLinkM3U8[1] = String(action.payload.val().streamLink.C1M); }
      if (this.streamLinkM3U8[2] != String(action.payload.val().streamLink.C2M)) { this.streamLinkM3U8[2] = String(action.payload.val().streamLink.C2M); }
      if (this.streamLinkM3U8[3] != String(action.payload.val().streamLink.C3M)) { this.streamLinkM3U8[3] = String(action.payload.val().streamLink.C3M); }
      if (this.streamLinkM3U8[4] != String(action.payload.val().streamLink.C4M)) { this.streamLinkM3U8[4] = String(action.payload.val().streamLink.C4M); }
      if (this.streamLinkM3U8[5] != String(action.payload.val().streamLink.C5M)) { this.streamLinkM3U8[5] = String(action.payload.val().streamLink.C5M); }
      if (this.streamLinkM3U8[6] != String(action.payload.val().streamLink.C6M)) { this.streamLinkM3U8[6] = String(action.payload.val().streamLink.C6M); }
      //Test link
      if (this.streamLinkM3U8[7] != String(action.payload.val().streamLink.T0M)) { this.streamLinkM3U8[7] = String(action.payload.val().streamLink.T0M); }

      //M3U*
      if (this.streamLinkYT[0] != String(action.payload.val().streamLink.C0Y)) { this.streamLinkYT[0] = String(action.payload.val().streamLink.C0Y); }
      if (this.streamLinkYT[1] != String(action.payload.val().streamLink.C1Y)) { this.streamLinkYT[1] = String(action.payload.val().streamLink.C1Y); }
      if (this.streamLinkYT[2] != String(action.payload.val().streamLink.C2Y)) { this.streamLinkYT[2] = String(action.payload.val().streamLink.C2Y); }
      if (this.streamLinkYT[3] != String(action.payload.val().streamLink.C3Y)) { this.streamLinkYT[3] = String(action.payload.val().streamLink.C3Y); }
      if (this.streamLinkYT[4] != String(action.payload.val().streamLink.C4Y)) { this.streamLinkYT[4] = String(action.payload.val().streamLink.C4Y); }
      if (this.streamLinkYT[5] != String(action.payload.val().streamLink.C5Y)) { this.streamLinkYT[5] = String(action.payload.val().streamLink.C5Y); }
      if (this.streamLinkYT[6] != String(action.payload.val().streamLink.C6Y)) { this.streamLinkYT[6] = String(action.payload.val().streamLink.C6Y); }
      //Test link
      if (this.streamLinkYT[7] != String(action.payload.val().streamLink.T0Y)) { this.streamLinkYT[7] = String(action.payload.val().streamLink.T0Y); }
      //Event* Custom Link
      if (this.streamLinkYT[8] != String(action.payload.val().streamLink.C0E)) { this.streamLinkYT[8] = String(action.payload.val().streamLink.C0E); }


      if (this.pageID=="akuAgG8qd00wYQa6Wgt9s2VK23nPwcf5wTiEkncBceCy0DcjTHi") {
          if( this.mainStreamTest != action.payload.val().Test.mainStream ) { 
            this.mainStreamTest = action.payload.val().Test.mainStream; 
            this.playEvent(this.mainStreamTest,0);   
          }
      } else {
          if( this.mainStream != action.payload.val().Main.mainStream ) { 
            this.mainStream = action.payload.val().Main.mainStream; 
            this.playEvent(this.mainStream,0);   
          }
      }
    });
  }

  akuCountDown(){
    this.diffIV.subscribe(n=>{
        this.diff = (this.akuDate - new Date().getTime()); 
        this.akuEventD = Math.floor(this.diff / (1000 * 60 * 60 * 24));
        this.akuEventH = Math.floor((this.diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.akuEventM = Math.floor((this.diff % (1000 * 60 * 60)) / (1000 * 60));
        this.akuEventS = Math.floor((this.diff % (1000 * 60)) / 1000);
      });
  }

  playEvent(page:string, campus:number, type?:string) { 
    this.mainPage=page;
    let pageTitle=''; 
    if (Number(this.streamStatus[campus]) != 0) {
      this.akuPlayer=0;
    // if (campus == 0 ) { this.mainStream=page; }
    } 

    if (page == 'Live' ) {
      pageTitle = 'AKU_'+page +' '+this.streamTitleTxt[campus];
    } else {
      pageTitle = 'AKU_'+page;
    }
    this.akuGA.logEvent(pageTitle);
    this.akuPlayerVideo='M';
    switch(page) {
      case 'Home':
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/welcome-aku-f23.jpg' width='100%' height='auto' alt='AKU Live'>";
        //bmPlayer(page, pageTitle, this.streamLinkM3U8[campus]);
        bmStopPlayer();
        break;
      case 'Test':
        this.isLive = 'block';
        this.akuPlayerContent="";
        bmPlayer(page, pageTitle, this.streamLinkM3U8[7]);
        break;
      case 'Global':
      case 'Live':
        bmStopPlayer();
        this.akuPlayerContent="";
        if (this.streamStatus[campus]=="M") { 
          this.isLive = 'block';
          this.akuPlayerVideo='M';
          this.akuPlayer=campus;
          bmPlayer(page, pageTitle, this.streamLinkM3U8[campus]); 
        } else if (this.streamStatus[campus]=="Y") { 
          this.isLive = 'none';
          this.akuPlayerVideo='Y';
          this.akuPlayer=campus;
          this.safeURL=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+this.streamLinkYT[campus]+'?autoplay=1&modestbranding=1&enablejsapi=1&playsinline=1');
        } 
        break;   
      case 'Event':
        bmStopPlayer();
        this.isLive = 'none';
        this.akuPlayerContent="";
        this.akuPlayerVideo='Y';
        this.akuPlayer=campus;
        this.safeURL=this._sanitizer.bypassSecurityTrustResourceUrl(this.streamLinkYT[8]);
        break; 
      case 'Manual':
        bmStopPlayer();
        this.isLive = 'none';
        this.akuPlayerContent="";
        this.akuPlayerVideo='Y';
        this.akuPlayer=campus;
        this.safeURL=this._sanitizer.bypassSecurityTrustResourceUrl("https://video.ibm.com/embed/24192386");
        break;                    
      case 'Counter':
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/counter-aku-f23.jpg' width='100%' height='auto' alt='AKU Convacation'>";
        bmStopPlayer();
        this.akuCountDown();
        break;              
      case 'Thankyou':
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/thankyou-aku-f23.jpg' width='100%' height='auto' alt='AKU Convacation'>";
        bmStopPlayer();
        break;    
      case 'Issue':
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/error-aku-f23.jpg' width='100%' height='auto' alt='AKU Convacation'>";
        bmStopPlayer();
        break;   
      case 'AKU':
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/aku.jpg' width='100%' height='auto' alt='AKU Convacation'>";
        bmStopPlayer();
        break;                           
      default:
        this.isLive = 'none';
        this.akuPlayerContent="<img src='assets/aku.jpg' width='100%' height='auto' alt='AKU Convacation'>";
        bmPlayer(page, pageTitle, this.streamLinkM3U8[campus]);
        break;           
    }
  }
}