import { Component  } from '@angular/core';

declare function bmPlayer(p:string, params:any): any;

@Component({
  selector: 'itv-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  constructor(){ }
}