import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAnalyticsModule, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';

import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AkuhComponent } from './akuh/akuh.component';
import { AdminComponent } from './admin/admin.component';
import { FormsModule } from '@angular/forms';
import { ItdemoComponent } from './itdemo/itdemo.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { LiveComponent } from './live/live.component';

const routes: Routes = [
  { path: 'live', component: LiveComponent },
  { path: 'live/:id', component: LiveComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'feedback/:id', component: FeedbackComponent },
  { path: 'akuAdmin23X8lm1Zs4eT2bbwOCKlgAnb5s8fdEE23', component: AdminComponent },
  { path: '', redirectTo: '/live', pathMatch: 'full'},
  { path: '**',redirectTo: '/live', pathMatch: 'full'}
];

@NgModule({
  declarations: [
    AppComponent,
    LiveComponent,
    AkuhComponent,
    AdminComponent,
    ItdemoComponent,
    FeedbackComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    MatToolbarModule,
    MatCardModule,
    MatGridListModule,
    MatSelectModule,
    MatRadioModule, 
    FormsModule, 
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule,
    RouterModule.forRoot(routes) 
  ],
  providers: [
    UserTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
