<router-outlet></router-outlet>

<div style="padding: 10px; text-align: center; background: white;"><img src="assets/akuhklogo.png" alt="AKU" width="462px" style="max-width: 100%;"></div>
<div style="background-color:#EC1C24;height: 5px;"></div>
<div class="h_iframe" style="margin: auto;position: relative;" [style.max-width]="mainWidth">  
  <div id='player' style="position: relative;" [ngStyle]="{'display': isLive }" ></div>

  <div style="text-align: left; position: relative;" [innerHTML]="akuPlayerContent"></div>
  <!-- <div *ngIf="!akuPlayerContent" style="text-align: left; position: relative;"><video muted autoplay loop width='100%' height='100%' id='akumVideo'><source src='assets/akuh.mp4' type='video/mp4'></video></div> -->

  <div *ngIf="mainPage=='Counter'" style="text-align: left; position: absolute; bottom:8%; right:5%; padding-left: 3%; color: #EC1C24;">
    <div class="grid-container2" >
      <div class="grid-item2">
        <span style="font-size:6vw;">{{ akuEventH | number:'2.0' }} <span style="font-size:1.5vw;display:block;">HOURS</span></span>
      </div>
      <div class="grid-item2">
        <span style="font-size:6vw;"> {{ akuEventM | number:'2.0' }} <span style="font-size:1.5vw;display:block;">MINUTES</span></span>
      </div>
      <div class="grid-item2">
        <span style="font-size:6vw;"> {{ akuEventS | number:'2.0' }} <span style="font-size:1.5vw;display:block;">SECONDS</span></span>
      </div>
    </div>
  </div>  

  <div *ngIf="mainPage=='Live'">
    <div class="grid-container">
      <div class="grid-item" (click)="playEvent('Live',1)" [ngStyle]="{'cursor':streamStatus[1]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-pk1.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==1}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[1]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==1 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[1]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playTitle" [innerHTML]="akuCampuses[1]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[1]=='1'? 'none':'block' }"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',2)" [ngStyle]="{'cursor':streamStatus[2]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-pk2.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==2}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[2]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==2 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[2]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playTitle" [innerHTML]="akuCampuses[2]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[2]=='1'? 'none':'block' }"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',3)" [ngStyle]="{'cursor':streamStatus[3]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-pk3.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==3}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[3]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==3 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[3]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playTitle" [innerHTML]="akuCampuses[3]"></div>
        <!--<div class="playError"> Off-line </div>-->
        <div class="playBtn" [ngStyle]="{'display':streamStatus[3]=='1'? 'none':'block' }"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',4)" [ngStyle]="{'cursor':streamStatus[4]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-ug1.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==4}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[4]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==4 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[4]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playTitle" [innerHTML]="akuCampuses[4]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[4]=='1'? 'none':'block' }"></div>
      </div>                  
      <div class="grid-item" (click)="playEvent('Live',5)" [ngStyle]="{'cursor':streamStatus[5]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-ke1.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==5}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[5]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==5 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[5]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playTitle" [innerHTML]="akuCampuses[5]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[5]=='1'? 'none':'block' }"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',6)" [ngStyle]="{'cursor':streamStatus[6]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-tz1.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==6}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[6]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==6 ? 'block':'none' }" > &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[6]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playTitle" [innerHTML]="akuCampuses[6]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[6]=='1'? 'none':'block' }"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',7)" [ngStyle]="{'cursor':streamStatus[7]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-ke2.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==7}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[7]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==7 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div> 
        <div class="playDone" [ngStyle]="{'display':streamStatus[7]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>       
        <div class="playTitle" [innerHTML]="akuCampuses[7]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[7]=='1'? 'none':'block' }"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',8)" [ngStyle]="{'cursor':streamStatus[8]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-tz2.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==8}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[8]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==8 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>        
        <div class="playDone" [ngStyle]="{'display':streamStatus[8]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playTitle" [innerHTML]="akuCampuses[8]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[8]=='1'? 'none':'block' }"></div>
      </div>            
      <div class="grid-item" (click)="playEvent('Live',9)" [ngStyle]="{'cursor':streamStatus[9]=='1'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-ke3.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==9}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[9]=='1'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==9 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div> 
        <div class="playDone" [ngStyle]="{'display':streamStatus[9]=='1' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>       
        <div class="playTitle" [innerHTML]="akuCampuses[9]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[9]=='1'? 'none':'block' }"></div>
      </div>                            
    </div>    
  </div>

  <div *ngIf="mainPage=='Live'" style="padding-top: 10px;">
    <div class="cbList">
    <ul>
      <li> <a href="../../assets/2021AKU_ConvocationPK.pdf" target="_blank">Convocation Programme 2021<br>Pakistan</a> </li>
      <li> <a href="../../assets/2021AKU_ConvocationEA.pdf" target="_blank">Convocation Programme 2021<br>East Africa</a> </li>
    </ul>
    </div>
    <!-- <img src='assets/AKUProgramme.jpg' width='100%' height='auto' alt='AKU Convacation'>  -->
  </div>

</div>

<div class="footer">	
  <div class="aku-link"><a href="https://hospitals.aku.edu/pakistan"> Find out more at hospitals.aku.edu/pakistan </a> </div>
  
  <span style="position: absolute; cursor: pointer;" *ngIf="pageID=='aku-AgG8qCQd00wYYQa6Wgt89s2VKz'">
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Home',0);">Home</a> 
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Test',0)">Test</a> 
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Issue',0)">Error</a>
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Counter',0)">Counter</a> 
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Global',0)">Live</a> 
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Thankyou',0)">Thankyou</a>
  </span>

  <div id="social-div">
    <ul>
      <li id="social-fb"><a href="https://www.facebook.com/AKUHPakistan/">Facebook</a></li>
      <li id="social-yt"><a href="https://www.youtube.com/AgaKhanUniversityHospitalPakistan">YouTube</a></li>
    </ul>
  </div>
</div>