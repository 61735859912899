export const environment = {
  production: false,
  firebaseConfig:  {
    apiKey: "AIzaSyAOlrb30PSL6BbDFJbP2IBQ4ESQ0-EMfFM",
    authDomain: "megafy-clc.firebaseapp.com",
    databaseURL: "https://akdn.firebaseio.com/",
    projectId: "megafy-clc",
    //storageBucket: "megafy-clc.appspot.com",
    messagingSenderId: "778977888132",
    appId: "1:778977888132:web:ed75b62b4d83f98822ffd1",
    measurementId: "G-9XVRESD7Z3"
  }
};