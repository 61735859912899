import { Component } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {

  isAdmin = false;
  akuObj:AngularFireObject<any>;

  mainStream:string="Home"; // Home Counter Test Global Live Thankyou
  mainStreamTest:string="Home"; // Home Counter Global Test Live Thankyou
  streamLinkM3U8:string[]=['', '', '', '', '', '', '', '' ];
  streamLinkYT:string[]=['', '', '', '', '', '', '', '' ];
  streamTitle:string[]=['', '', '', '', '', '', '', '' ];
  streamTitleTxt:string[]=['', '', '', '', '', '', '', '' ];
  streamStatus:string[]=['M', 'M', 'M', 'M', 'M', 'M', 'M', 'M', 'M', 'M' ];
 
  constructor (private akuDB: AngularFireDatabase) {
    this.akuObj = akuDB.object('AKU-F2023');
    this.akuObj.snapshotChanges().subscribe(action => {
      this.streamTitle[0] = String(action.payload.val().streamTitle.C0); 
      this.streamTitle[1] = String(action.payload.val().streamTitle.C1); 
      this.streamTitle[2] = String(action.payload.val().streamTitle.C2); 
      this.streamTitle[3] = String(action.payload.val().streamTitle.C3); 
      this.streamTitle[4] = String(action.payload.val().streamTitle.C4);
      this.streamTitle[5] = String(action.payload.val().streamTitle.C5); 
      this.streamTitle[6] = String(action.payload.val().streamTitle.C6); 
      this.streamTitle[7] = String(action.payload.val().streamTitle.T0); 

      this.streamTitleTxt = [
        this.streamTitle[0].replace('<br>',' and '),
        this.streamTitle[1].replace('<br>',' and '),
        this.streamTitle[2].replace('<br>',' and '),
        this.streamTitle[3].replace('<br>',' and '),
        this.streamTitle[4].replace('<br>',' and '),
        this.streamTitle[5].replace('<br>',' and '),
        this.streamTitle[6].replace('<br>',' and '),
        this.streamTitle[7].replace('<br>',' and '),
      ];

      this.streamStatus[0] = String(action.payload.val().streamStatus.C0); 
      this.streamStatus[1] = String(action.payload.val().streamStatus.C1); 
      this.streamStatus[2] = String(action.payload.val().streamStatus.C2); 
      this.streamStatus[3] = String(action.payload.val().streamStatus.C3); 
      this.streamStatus[4] = String(action.payload.val().streamStatus.C4); 
      this.streamStatus[5] = String(action.payload.val().streamStatus.C5); 
      this.streamStatus[6] = String(action.payload.val().streamStatus.C6); 
      this.streamStatus[7] = String(action.payload.val().streamStatus.T0); 

      //M3U
      this.streamLinkM3U8[0] = String(action.payload.val().streamLink.C0M); 
      this.streamLinkM3U8[1] = String(action.payload.val().streamLink.C1M); 
      this.streamLinkM3U8[2] = String(action.payload.val().streamLink.C2M); 
      this.streamLinkM3U8[3] = String(action.payload.val().streamLink.C3M); 
      this.streamLinkM3U8[4] = String(action.payload.val().streamLink.C4M); 
      this.streamLinkM3U8[5] = String(action.payload.val().streamLink.C5M); 
      this.streamLinkM3U8[6] = String(action.payload.val().streamLink.C6M); 
      this.streamLinkM3U8[7] = String(action.payload.val().streamLink.T0M); 

      //Youtube
      this.streamLinkYT[0] = String(action.payload.val().streamLink.C0Y);
      this.streamLinkYT[1] = String(action.payload.val().streamLink.C1Y);
      this.streamLinkYT[2] = String(action.payload.val().streamLink.C2Y);
      this.streamLinkYT[3] = String(action.payload.val().streamLink.C3Y);
      this.streamLinkYT[4] = String(action.payload.val().streamLink.C4Y);
      this.streamLinkYT[5] = String(action.payload.val().streamLink.C5Y);
      this.streamLinkYT[6] = String(action.payload.val().streamLink.C6Y);
      this.streamLinkYT[7] = String(action.payload.val().streamLink.T0Y);

      this.mainStreamTest = action.payload.val().Test.mainStream;
      this.mainStream = action.payload.val().Main.mainStream;
    });

  }
  
  onSubmit(form:NgForm) {
    this.akuDB.object('AKU-F2023/streamLink').update(
      {
        C0Y: this.streamLinkYT[0],
        C1Y: this.streamLinkYT[1],
        C2Y: this.streamLinkYT[2],
        C3Y: this.streamLinkYT[3],
        C4Y: this.streamLinkYT[4],
        C5Y: this.streamLinkYT[5],
        C6Y: this.streamLinkYT[6],
        T0Y: this.streamLinkYT[7],
        C0M: this.streamLinkM3U8[0],
        C1M: this.streamLinkM3U8[1],
        C2M: this.streamLinkM3U8[2],
        C3M: this.streamLinkM3U8[3],
        C4M: this.streamLinkM3U8[4],
        C5M: this.streamLinkM3U8[5],
        C6M: this.streamLinkM3U8[6],
        T0M: this.streamLinkM3U8[7]
      });
    this.akuDB.object('AKU-F2023/streamStatus').update(
      {
        C0: this.streamStatus[0],
        C1: this.streamStatus[1],
        C2: this.streamStatus[2],
        C3: this.streamStatus[3],
        C4: this.streamStatus[4],
        C5: this.streamStatus[5],
        C6: this.streamStatus[6],
        T0: this.streamStatus[7]
      });

      this.akuDB.object('AAKU-F2023KU/Test').update({ mainStream: this.mainStreamTest });
      this.akuDB.object('AKU-F2023/Main').update({ mainStream: this.mainStream });
  }

  onSubmitLogin(form:NgForm) {
    if (form.controls.User.value=="pC4TVKLNQEk1ePBJd1RzVucub")  this.isAdmin = true; else this.isAdmin=false;
  }
}