<router-outlet></router-outlet>

<div style="padding: 10px; text-align: center; background: white;"><img src="assets/AKU_logo.jpg" alt="AKU" width="250px"></div>
<div style="background-color: #00843D;height: 5px;"></div>

<style>
#playerYT { position: relative; overflow: hidden; width: 100%;}
#playerYT::after { display: block; content: ""; padding-top: 56.25%;}
#playerYT iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
</style>

<div class="h_iframe" style="margin: auto;position: relative;" [style.max-width]="mainWidth">  
  <div id='player' style="position: relative;" [ngStyle]="{'display': isLive }" ></div>
  <div style="text-align: left; position: relative; box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;" [innerHTML]="akuPlayerContent" *ngIf="akuPlayerVideo!='Y'"></div>
  
  <div id="playerYT" *ngIf="akuPlayerVideo=='Y'">
    <iframe [src]="safeURL" title="AKU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>

  <div *ngIf="mainPage=='Counter'" style="text-align: left; position: absolute; top:50%; padding-left: 3%; color: #008149;">
    <div class="grid-container2" >
      <div class="grid-item2">
        <span [style.font-size]="counterSize">{{ akuEventH | number:'2.0' }} </span>
        <br><span [style.font-size]="counterTSize">HOURS</span>
      </div>
      <div class="grid-item2">
        <span [style.font-size]="counterSize"> {{ akuEventM | number:'2.0' }} </span>
        <br><span [style.font-size]="counterTSize">MINUTES</span>
      </div>
      <div class="grid-item2">
        <span [style.font-size]="counterSize"> {{ akuEventS | number:'2.0' }} </span>
        <br><span [style.font-size]="counterTSize">SECONDS</span>
      </div>
    </div>
  </div>  

  <div *ngIf="mainPage=='Live'">
    <div class="grid-container">
      <div class="grid-item" (click)="playEvent('Live',1)" [ngStyle]="{'cursor':streamStatus[1]=='C'||streamStatus[1]=='E'? 'not-allowed':'pointer'}">
        <img mat-card-image src="assets/campus-ke23.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==1}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[1]=='C'||streamStatus[1]=='E'?'none':'block'}"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==1 ? 'block':'none'}"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[1]=='C'?'block':'none'}"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playError" [ngStyle]="{'display':streamStatus[1]=='E'?'block':'none'}"> Technical Issue</div>
        <div class="playTitle" [innerHTML]="streamTitle[1]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[1]=='C'||streamStatus[1]=='E'?'none':'block'}"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',2)" [ngStyle]="{'cursor':streamStatus[2]=='C'||streamStatus[2]=='E'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-ug23.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==2}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[2]=='C'||streamStatus[2]=='E'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==2 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[2]=='C' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playError" [ngStyle]="{'display':streamStatus[2]=='E'?'block':'none'}"> Technical Issue</div>
        <div class="playTitle" [innerHTML]="streamTitle[2]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[2]=='C'||streamStatus[2]=='E'? 'none':'block' }"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',3)" [ngStyle]="{'cursor':streamStatus[3]=='C'||streamStatus[3]=='E'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-tz23.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==3}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[3]=='C'||streamStatus[3]=='E'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==3 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[3]=='C' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playError" [ngStyle]="{'display':streamStatus[3]=='E'?'block':'none'}"> Technical Issue</div>
        <div class="playTitle" [innerHTML]="streamTitle[3]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[3]=='C'||streamStatus[3]=='E'? 'none':'block' }"></div>
      </div>
      <div class="grid-item" (click)="playEvent('Live',4)" [ngStyle]="{'cursor':streamStatus[4]=='C'||streamStatus[4]=='E'? 'not-allowed':'pointer' }">
        <img mat-card-image src="assets/campus-pk23.jpg" alt="Campus" [ngClass] = "{'imgPlaying': akuPlayer==4}">
        <div class="playIco" [ngStyle]="{'display':streamStatus[4]=='C'||streamStatus[4]=='E'? 'none':'block' }"></div>
        <div class="playNow" [ngStyle]="{'display':akuPlayer==4 ? 'block':'none' }"> &nbsp; Playing Now &nbsp; </div>
        <div class="playDone" [ngStyle]="{'display':streamStatus[4]=='C' ? 'block':'none' }"> &nbsp; Ceremony Closed &nbsp; </div>
        <div class="playError" [ngStyle]="{'display':streamStatus[4]=='E'?'block':'none'}"> Technical Issue</div>
        <div class="playTitle" [innerHTML]="streamTitle[4]"></div>
        <div class="playBtn" [ngStyle]="{'display':streamStatus[4]=='C'||streamStatus[4]=='E'? 'none':'block' }"></div>
      </div>                                         
    </div>    
  </div>

  <div *ngIf="mainPage=='Live'" style="padding-top: 10px;">
    <div class="cbList">
    <ul>
      <!-- <li> <a href="../../assets/2021AKU_ConvocationPK.pdf" target="_blank">Convocation Programme 2022<br>Pakistan</a> </li>
      <li> <a href="../../assets/2021AKU_ConvocationEA.pdf" target="_blank">Convocation Programme 2022<br>East Africa</a> </li> -->
    </ul>
    </div>
    <!-- <img src='assets/AKUProgramme.jpg' width='100%' height='auto' alt='AKU Convacation'>  -->
  </div>

</div>

<div class="footer">	
  <div class="aku-link"><a href="http://www.aku.edu"> Find out more at aku.edu </a> </div>

  <span style="position: absolute; cursor: pointer;" *ngIf="pageID=='akuAgG8qd00wYQa6Wgt9s2VK23nPwcf5wTiEkncBceCy0DcjTHi'">
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Home',0);">Home</a>
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Event',0)">Event</a>
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Global',0)">Global</a>
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Test',7)">Test Global</a>
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Counter',0)">Counter</a>
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Live',0)">Live</a>
    &nbsp; <a style="color: #ffffff; text-decoration:none; font-size:small;" (click)="playEvent('Thankyou',0)">Thankyou</a>
  </span>

  <div id="social-div">
    <ul>
      <li id="social-tt"><a href="https://twitter.com/akuglobal">Twitter</a></li>
      <li id="social-ig"><a href="https://www.instagram.com/akuglobal/">Instagram</a> </li>
      <li id="social-fb"><a href="https://www.facebook.com/AKUGlobal">Facebook</a></li>
      <li id="social-li"><a href="https://www.linkedin.com/school/14725">LinkedIn</a></li>    
      <li id="social-yt"><a href="https://www.youtube.com/channel/UCO-pBwoHl40sZFtRhKI-Blw">YouTube</a></li>
    </ul>
  </div>
</div>