<div style="padding: 10px; text-align: center; background: white;"><img src="assets/AKU_logo.jpg" alt="AKU" width="250px"></div>
<div style="background-color: #00843D;height: 5px;"></div>

<div style="padding-top: 30px;">

  <form #akuFormLogin="ngForm" (ngSubmit)="onSubmitLogin(akuFormLogin)">
    <div *ngIf='!isAdmin' style=" margin-left: auto; margin-right: auto; text-align: center;">
        <h1>Demo Access</h1>
        <input type="password"  name="User" autocomplete="off"  ngModel>
        <input type="submit" value="Login">
    </div>
  </form>
  <form #akuForm="ngForm" (ngSubmit)="onSubmit(akuForm)">  
    <table style=" margin-left: auto; margin-right: auto;" *ngIf='isAdmin'>
      <tr>
        <td style="text-align: right;">Live: Active Page &nbsp;</td>
        <td>
          <select name='mainStrm' [(ngModel)]="mainStream">
            <option value="Home">Home Page</option>
            <option value="Counter">Counter Page</option>
            <option value="Global">Global - Live Single Stream</option>
            <option value="Live">All Campus - Live Multi Stream</option>
            <option value="Thankyou">Thankyou Page</option>    
            <option value="Test">Test Page</option>
            <option value="Issue">Technical Difficulty Page</option>
          </select>
        </td>
      </tr>
      <tr>
        <td style="text-align: right;">Test: Active Page &nbsp;</td>
        <td>
          <select name='mainStrmTst' [(ngModel)]="mainStreamTest">
            <option value="Home">Home Page</option>
            <option value="Counter">Counter Page</option>
            <option value="Global">Global - Live Single Stream</option>
            <option value="Live">All Campus - Live Multi Stream</option>
            <option value="Thankyou">Thankyou Page</option>    
            <option value="Test">Test Page</option>
            <option value="Issue">Technical Difficulty Page</option>
          </select>
        </td>
      </tr>
      <tr><td colspan="2"><hr></td></tr>

      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[0] }} &nbsp;</td>
        <td>
          M3U8 <input type="radio" name="RB0" value="M" [(ngModel)]="streamStatus[0]">
          YouTube <input type="radio" name="RB0" value="Y" [(ngModel)]="streamStatus[0]">
        </td>
      </tr>
      <tr style="background-color: #e0e0e0;">
        <td style="text-align: right;">{{ streamTitleTxt[7] }} &nbsp;</td>
        <td>
          M3U8 <input type="radio" name="RB7" value="M" [(ngModel)]="streamStatus[7]">
          YouTube <input type="radio" name="RB7" value="Y" [(ngModel)]="streamStatus[7]">
        </td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[1] }} &nbsp;</td>
        <td>
          M3U8 <input type="radio" name="RB1" value="M" [(ngModel)]="streamStatus[1]">
          YouTube <input type="radio" name="RB1" value="Y" [(ngModel)]="streamStatus[1]">
          Closed <input type="radio" name="RB1" value="C" [(ngModel)]="streamStatus[1]">
          Error <input type="radio" name="RB1" value="E"  [(ngModel)]="streamStatus[1]">    
        </td>
      </tr>
      <tr style="background-color: #e0e0e0;">
        <td style="text-align: right;">{{ streamTitleTxt[2] }} &nbsp;</td>
        <td>
          M3U8 <input type="radio" name="RB2" value="M" [(ngModel)]="streamStatus[2]">
          YouTube <input type="radio" name="RB2" value="Y" [(ngModel)]="streamStatus[2]">
          Closed <input type="radio" name="RB2" value="C" [(ngModel)]="streamStatus[2]">
          Error <input type="radio" name="RB2" value="E"  [(ngModel)]="streamStatus[2]"> 
        </td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[3] }} &nbsp;</td>
        <td>
          M3U8 <input type="radio" name="RB3" value="M" [(ngModel)]="streamStatus[3]">
          YouTube <input type="radio" name="RB3" value="Y" [(ngModel)]="streamStatus[3]">
          Closed <input type="radio" name="RB3" value="C" [(ngModel)]="streamStatus[3]">
          Error <input type="radio" name="RB3" value="E"  [(ngModel)]="streamStatus[3]"> 
        </td>
      </tr>
      <tr style="background-color: #e0e0e0;">
        <td style="text-align: right;">{{ streamTitleTxt[4] }} &nbsp;</td>
        <td>
          M3U8 <input type="radio" name="RB4" value="M" [(ngModel)]="streamStatus[4]">
          YouTube <input type="radio" name="RB4" value="Y" [(ngModel)]="streamStatus[4]">
          Closed <input type="radio" name="RB4" value="C" [(ngModel)]="streamStatus[4]">
          Error <input type="radio" name="RB4" value="E"  [(ngModel)]="streamStatus[4]"> 
        </td>
      </tr>

      <tr><td colspan="2"><hr><h4>YouTube Links</h4></td></tr>

      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[0] }} &nbsp;</td>
        <td><input type="text" name='streamLinkY0' [(ngModel)]="streamLinkYT[0]" style="width: 100px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[7] }} &nbsp;</td>
        <td><input type="text" name='streamLinkY7' [(ngModel)]="streamLinkYT[7]" style="width: 100px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[1] }} &nbsp;</td>
        <td><input type="text" name='streamLinkY1' [(ngModel)]="streamLinkYT[1]" style="width: 100px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[2] }} &nbsp;</td>
        <td><input type="text" name='streamLinkY2' [(ngModel)]="streamLinkYT[2]" style="width: 100px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[3] }} &nbsp;</td>
        <td><input type="text" name='streamLinkY3' [(ngModel)]="streamLinkYT[3]" style="width: 100px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[4] }} &nbsp;</td>
        <td><input type="text" name='streamLinkY4' [(ngModel)]="streamLinkYT[4]" style="width: 100px;" ></td>
      </tr>

      <tr><td colspan="2"><hr><h4>M3U8 Links</h4></td></tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[0] }} &nbsp;</td>
        <td><input type="text" name='streamLinkM0' [(ngModel)]="streamLinkM3U8[0]" style="width: 500px;" ></td>
      </tr>
      <tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[7] }} &nbsp;</td>
        <td><input type="text" name='streamLinkM7' [(ngModel)]="streamLinkM3U8[7]" style="width: 500px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[1] }} &nbsp;</td>
        <td><input type="text" name='streamLinkM1' [(ngModel)]="streamLinkM3U8[1]" style="width: 500px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[2] }} &nbsp;</td>
        <td><input type="text" name='streamLinkM2' [(ngModel)]="streamLinkM3U8[2]" style="width: 500px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[3] }} &nbsp;</td>
        <td><input type="text" name='streamLinkM3' [(ngModel)]="streamLinkM3U8[3]" style="width: 500px;" ></td>
      </tr>
      <tr>
        <td style="text-align: right;">{{ streamTitleTxt[4] }} &nbsp;</td>
        <td><input type="text" name='streamLinkM4' [(ngModel)]="streamLinkM3U8[4]" style="width: 500px;" ></td>
      </tr>
      <tr><td colspan="2"><hr></td></tr>
      <tr><td></td><td><br><input type="submit" value="Save"> </td></tr>
    </table>
  </form>
</div>

<div class="footer">	
  <div class="aku-link"><a href="http://www.aku.edu"> Find out more at www.aku.edu </a> </div>
  <div id="social-div">
    <ul>
      <li id="social-tt"><a href="https://twitter.com/akuglobal">Twitter</a></li>
      <li id="social-ig"><a href="https://www.instagram.com/akuglobal/">Instagram</a> </li>
      <li id="social-fb"><a href="https://www.facebook.com/AKUGlobal">Facebook</a></li>
      <li id="social-li"><a href="https://www.linkedin.com/school/14725">LinkedIn</a></li>    
      <li id="social-yt"><a href="https://www.youtube.com/channel/UCO-pBwoHl40sZFtRhKI-Blw">YouTube</a></li>
    </ul>
  </div>
</div>
