import { Component, OnInit, HostListener} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  pageID:string|null=null;
  pageSrc:SafeResourceUrl="https://form.typeform.com/to/zkje8qn5?typeform-medium=embed-snippet#token=na";
  mainWidth="100%";
  counterSize="10vw";

  @HostListener('window:resize', ['$event'])
  onResize() { this.mainResize(); }

  mainResize(){
    this.mainWidth=((window.innerHeight-300)*1.75)+"px";
    if (window.innerWidth > ((window.innerHeight-300)*1.75) ) {
      this.counterSize=(((window.innerHeight-300)*1.75)/8)+"px";  
    } else {
      this.counterSize=((window.innerWidth)/8)+"px";  
    }   
  }

  constructor(private route: ActivatedRoute, public sanitizer: DomSanitizer) { 
    this.pageID = this.route.snapshot.paramMap.get('id'); 
  }

  ngOnInit(): void { 

    this.pageID = "https://form.typeform.com/to/zkje8qn5?typeform-medium=embed-snippet#token="+this.route.snapshot.paramMap.get('id');
    this.pageSrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.pageID);
 }

  }
